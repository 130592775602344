<template>
  <section id="edit-subscriptions">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("subscriptions.titleEdit") }}</h1>
        <h5 class="text-primary">
          {{ $t("subscriptions.subtitleEdit") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-row class="align-items-center">
          <b-col>
            <router-link to="/platform/subscriptions">
              <b-button variant="danger">
                {{
                  $t("dataGeneric.goBack")
                }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Datos de la suscripción -->
    <b-row>
      <b-col>
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="InfoIcon" />
              <span>{{ $t("subscriptions.data") }}</span>
            </template>
            <b-row class="match-height mt-2">
              <b-col>
                <b-overlay
                  variant="transparent"
                  :show="showDatos"
                >
                  <b-card>
                    <b-card-header>
                      <b-card-title>{{ $t("subscriptions.data") }}</b-card-title>
                    </b-card-header>

                    <b-card-body>
                      <b-row>
                        <b-col
                          lg="12"
                          class="ml-2 mb-2"
                        >
                          <b-form @submit.prevent="updateDatos">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('subscriptions.name')"
                            >
                              <b-form-input
                                v-model="name"
                                minlength="3"
                                class="mr-1"
                                autocomplete="new-password"
                              />
                            </b-form-group>
                            <b-row>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.max')"
                                >
                                  <b-form-input
                                    v-model="maxDevices"
                                    type="number"
                                    :min="0"
                                    class="mr-1"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.freeDays')"
                                >
                                  <b-form-input
                                    v-model="freeDays"
                                    type="number"
                                    :min="0"
                                    class="mr-1"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col class="align-self-center">
                                <b-form-group class="mb-2 mr-1">
                                  <b-form-checkbox
                                    id="isActive"
                                    v-model="isActive"
                                    name="isActive"
                                    :switch="true"
                                  >
                                    {{ $t("subscriptions.active") }}
                                  </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.timeline')"
                                >
                                  <b-form-select
                                    id="Periodo"
                                    v-model="interval"
                                    label="text"
                                    :options="intervalOptions"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('dataGeneric.description')"
                                >
                                  <b-form-textarea
                                    id="textarea"
                                    v-model="desc"
                                    :placeholder="$t('dataGeneric.description')"
                                    rows="3"
                                    max-rows="6"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col
                                class="align-self-center"
                                md="3"
                              >
                                <b-form-group class="mb-2 mr-1">
                                  <b-form-checkbox
                                    id="isAdFree"
                                    v-model="isAdFree"
                                    name="isAdFree"
                                    :switch="true"
                                  >
                                    {{ $t("isAdFree") }}
                                  </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                              <b-col
                                class="align-self-center"
                                md="3"
                              >
                                <b-form-group class="mb-2 mr-1">
                                  <b-form-checkbox
                                    id="isUnlock4k"
                                    v-model="isUnlock4k"
                                    name="isUnlock4k"
                                    :switch="true"
                                  >
                                    {{ $t("isUnlock4k") }}
                                  </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                              <b-col
                                class="align-self-center"
                                md="3"
                              >
                                <b-form-group class="mb-2 mr-1">
                                  <b-form-checkbox
                                    id="isUnlockDownload"
                                    v-model="isUnlockDownload"
                                    name="isUnlockDownload"
                                    :switch="true"
                                  >
                                    {{ $t("isUnlockDownload") }}
                                  </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col class="mt-2">
                                <b-button
                                  class="mr-2"
                                  type="submit"
                                  variant="success"
                                >
                                  {{
                                    $t("dataGeneric.save")
                                  }}
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-overlay>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            v-if="isStripeGestioned"
          >
            <template #title>
              <feather-icon icon="FilePlusIcon" />
              <span>{{ $t("subscriptions.other") }}</span>
            </template>
            <!-- Otros datos -->
            <b-row
              class="match-height mt-2"
            >
              <b-col>
                <b-overlay
                  variant="transparent"
                  :show="showOtros"
                >
                  <b-card>
                    <b-card-header>
                      <b-card-title>{{ $t("subscriptions.other") }}</b-card-title>
                    </b-card-header>
                    <b-card-body>
                      <b-row>
                        <b-col
                          lg="12"
                          class="ml-2 mb-2"
                        >
                          <b-form @submit.prevent="updateOtros">
                            <b-row>
                              <b-col>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('purchases.price') + '  ( ' + currencyType[currency] + ' )'"
                                >
                                  <b-form-input
                                    v-model="price"
                                    type="number"
                                    :min="0"
                                    :placeholder="currencyType[currency]"
                                    name="price"
                                    step="any"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col v-if="externalPayMethod == 'STR'">
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.product')"
                                >
                                  <b-form-input
                                    id="StripePI"
                                    v-model="subscriptionId"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col v-if="externalPayMethod == 'STR'">
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.price')"
                                >
                                  <b-form-input
                                    id="StripePC"
                                    v-model="planId"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col v-else>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  label="Epoch PiCode"
                                >
                                  <b-form-input
                                    id="EpochPC"
                                    v-model="planId"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col v-if="externalPayMethod == 'STR'">
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.app')"
                                >
                                  <b-form-input
                                    id="StripeAM"
                                    v-model="subscriptionAppId"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col v-else>
                                <b-form-group
                                  class="mb-2 mr-1"
                                  :label="$t('subscriptions.app')"
                                >
                                  <b-form-input
                                    id="EpochAM"
                                    v-model="subscriptionAppId"
                                    autocomplete="new-password"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-button
                              type="submit"
                              variant="success"
                            >
                              {{
                                $t("dataGeneric.save")
                              }}
                            </b-button>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-overlay>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon" />
              <span>{{ $t("Categories") }}</span>
            </template>
            <subscriptions-cat :info="categorySuscriptions" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BFormTextarea,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BTab,
  BTabs,
  BFormCheckbox,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import SubscriptionsCat from './SubscriptionsCat.vue'

export default {
  components: {
    BOverlay,
    SubscriptionsCat,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      desc: null,
      name: null,
      interval: 'YEAR',
      price: null,
      userData: getUserData(),
      intervalOptions: [
        { value: 'DAY', text: this.$t('code.Daily') },
        { value: 'WEEK', text: this.$t('code.Weekly') },
        { value: 'MONTH', text: this.$t('code.Monthly') },
        { value: 'YEAR', text: this.$t('code.Annual') },
        { value: 'LIFETIME', text: this.$t('code.Vitalicia') },
      ],
      currency: null,
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      freeDays: null,
      maxDevices: null,
      allCountry: null,
      country: null,
      isStripeGestioned: null,
      externalPayMethod: null,
      isActive: null,
      subscriptionAppId: null,
      subscriptionId: null,
      planId: null,
      categorySuscriptions: [],
      allCat: null,
      showDatos: false,
      showOtros: false,
      isAdFree: false,
      isUnlock4k: false,
      isUnlockDownload: false,
    }
  },

  async mounted() {
    await this.getData()
    await this.allCategories()
  },
  methods: {
    async getData() {
      const { id } = this.$route.params
      await axios
        .post('', {
          query: `
            query{
                allSubscription(id:"${id}",client:"${this.userData.profile.client.id}"){
                    edges{
                        node{
                            id
                            name
                            isAdFree
                            isUnlock4k
                            isUnlockDownload
                            interval
                            description
                            freeDays
                            price
                            maxDevices
                            country{
                                id
                                country
                                language {
                                id
                                }
                            }
                            client {
                              id
                              externalPayMethod
                              currency
                            } 
                            isActive
                            isStripeGestioned  
                             subscriptionId
                            subscriptionAppId
                            planId
                            categorySuscriptions {
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                        }
                    }
                } 
                allCountry{
                    edges{
                        node{
                        id
                        country
                        }
                    }
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.name = res.data.data.allSubscription.edges[0].node.name
          this.interval = res.data.data.allSubscription.edges[0].node.interval
          this.freeDays = res.data.data.allSubscription.edges[0].node.freeDays
          this.isAdFree = res.data.data.allSubscription.edges[0].node.isAdFree
          this.isUnlock4k = res.data.data.allSubscription.edges[0].node.isUnlock4k
          this.isUnlockDownload = res.data.data.allSubscription.edges[0].node.isUnlockDownload
          this.price = res.data.data.allSubscription.edges[0].node.price
          this.maxDevices = res.data.data.allSubscription.edges[0].node.maxDevices
          this.allCountry = res.data.data.allCountry.edges
          this.country = res.data.data.allSubscription.edges[0].node.country?.id
          this.isStripeGestioned = res.data.data.allSubscription.edges[0].node.isStripeGestioned
          this.isActive = res.data.data.allSubscription.edges[0].node.isActive

          this.desc = res.data.data.allSubscription.edges[0].node.description

          this.categorySuscriptions = res.data.data.allSubscription.edges[0].node.categorySuscriptions
            .edges.length === 0
            ? []
            : res.data.data.allSubscription.edges[0].node.categorySuscriptions
              .edges

          this.subscriptionAppId = res.data.data.allSubscription.edges[0].node.subscriptionAppId
          this.subscriptionId = res.data.data.allSubscription.edges[0].node.subscriptionId
          this.planId = res.data.data.allSubscription.edges[0].node.planId
          this.externalPayMethod = res.data.data.allSubscription.edges[0].node.client.externalPayMethod
          this.currency = res.data.data.allSubscription.edges[0].node.client.currency
        })
    },
    updateDatos(event) {
      this.showDatos = true
      event.preventDefault()
      const { id } = this.$route.params

      if (!this.isStripeGestioned) {
        this.price = 0
      }
      axios
        .post('', {
          variables: {
            id,
            name: this.name,
            interval: this.interval,
            maxDevices: this.maxDevices,
            freeDays: this.freeDays,
            isActive: this.isActive,
            isAdFree: this.isAdFree,
            isUnlock4k: this.isUnlock4k,
            isUnlockDownload: this.isUnlockDownload,
            desc: this.desc,
          },
          query: `
          mutation($id: ID!, $name: String,$maxDevices: Int,$freeDays: Int,$isActive: Boolean,
          $isAdFree: Boolean,$isUnlock4k: Boolean,$isUnlockDownload: Boolean,$interval: SubscriptionIntervalInput,$desc: String){
                updateSubscription(id: $id,input:{ 
                    name: $name,
                    interval: $interval,                                  
                    description: $desc,
                    maxDevices: $maxDevices,                    
                    freeDays: $freeDays,                    
                    isActive: $isActive             
                    isAdFree: $isAdFree             
                    isUnlock4k: $isUnlock4k             
                    isUnlockDownload: $isUnlockDownload             
                    }){
                    subscription{
                        id
                        name
                        interval
                        freeDays
                        price
                    }
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.showDatos = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showDatos = false
        })
    },
    updateOtros(event) {
      this.showOtros = true

      event.preventDefault()

      const { id } = this.$route.params
      axios
        .post('', {
          variables: {
            id,
            planId: this.planId,
            subscriptionAppId: this.subscriptionAppId,
            subscriptionId: this.subscriptionId,
            price: this.price,
          },
          query: `
          mutation($id: ID!,$planId: String,$price: Float,$subscriptionAppId: String,$subscriptionId: String){
                updateSubscription(id: $id,input:{             
                    planId: $planId,
                    subscriptionAppId: $subscriptionAppId,
                    subscriptionId: $subscriptionId,
                    price: $price
                    }){
                    subscription{
                    id
                    maxDevices
                    country{
                        id
                        country
                        language {
                        id
                        }
                    }
                    isActive
                    isStripeGestioned  
                    }
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)

          this.showOtros = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showOtros = false
        })
    },
    allCategories() {
      axios
        .post('', {
          query: `
            query{
              allCategories(client:"${this.userData.profile.client.id}"){
                edges{
                  node{
                    id
                    name
                    image
                  }
                }
              }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.allCat = res.data.data.allCategories.edges
          const array = []
          for (let i = 0; i < this.allCat.length; i += 1) {
            if (!this.existe(this.allCat[i].node.id)) {
              array.push(this.allCat[i])
            }
          }
          this.allCat = array.slice()
        })
    },
    existe(id) {
      let bool = false
      for (let j = 0; j < this.categorySuscriptions.length; j += 1) {
        if (this.categorySuscriptions[j].node.id === id) {
          bool = true
        }
      }
      return bool
    },
  },
}
</script>
<style lang="scss" scoped>
#edit-subscriptions .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#edit-subscriptions .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#edit-subscriptions .card-body h4 {
  font-size: 1.286rem !important;
}
</style>
